<template>
        <b-dropdown
            menu-class="dropdown-menu-end p-0"
            toggle-class="header-item noti-icon pb-0"
            variant="black"
            no-caret
            ref="notificationDropdown"
            @hidden="show_archived_notifications = false"
        >
            <template v-slot:button-content>
                <!--<i class="bx bx-bell bx-tada"></i>-->
                <font-awesome-icon class="fa-fw fa-xl" icon="fa-solid  fa-bell" />
                <span v-show="new_notifications_filtered.length > 0" class="badge bg-danger rounded-pill mt-2">
                    {{new_notifications_filtered.length}}
                </span>
            </template>
            <b-overlay :show="is_loading_inbox">
                <div no-body style="width: 300px;" class="border border-dark">
                    <div class="p-1 bg-primary bg-soft">
                        <div class="row align-items-center">
                            <div class="col d-flex">
                                <label class="form-check-label mt-1" >
                                    Notifications
                                </label>
                                <!-- <span class="form-check form-switch mt-1 me-3 ms-auto" style="font-size: 10px;">
                                    <span>
                                        <input class="form-check-input" style="cursor:pointer;" v-model="show_archived_notifications" type="checkbox" id="archivedNotificationSwitch"/>
                                        <label class="form-check-label" for="archivedNotificationSwitch">
                                            Show Archived
                                        </label>
                                    </span>
                                </span> -->
                            </div>
                        </div>
                    </div>
                    <b-tabs fill>
                        <b-tab>
                            <template #title>
                                <span class="me-2">
                                    New
                                </span>
                                <span v-show="new_notifications_filtered.length > 0" class="badge bg-danger rounded-pill mt-1">
                                    {{new_notifications_filtered.length}}
                                </span>
                                
                            </template>
                            <simplebar v-show="new_notifications_filtered.length > 0" style="max-height: 330px">
                                <a v-for="item in new_notifications_filtered" is_unread_notification :is="getComponent(item.data.type)" :key="item.id" :item="item" class="text-reset notification-item"></a>
                            </simplebar>
                            <b-alert class="mb-1 mx-1" :show="new_notifications_filtered.length == 0">
                                No new notifications
                            </b-alert>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <span class="me-2">
                                    All
                                </span>
                                <span v-show="read_notifications_filtered.length > 0" class="badge bg-secondary rounded-pill mt-1">
                                    {{read_notifications_filtered.length}}
                                </span>
                            </template>
                            <simplebar v-show="read_notifications_filtered.length > 0" style="max-height: 330px">
                                <a v-for="item in read_notifications_filtered" :is="getComponent(item.data.type)" :key="item.id" :item="item" class="text-reset notification-item"></a>
                            </simplebar>
                            <b-alert class="mb-1 mx-1" :show="read_notifications_filtered.length == 0">
                                No notifications
                            </b-alert>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-overlay>
        </b-dropdown>
</template>

<script>
import simplebar from 'simplebar-vue'

import ActionPlanCard from './notifivationCards/action-plan.vue'
import ChatCard from './notifivationCards/chat-message.vue'
import DocumentAcceptance from './notifivationCards/document-acceptance-new.vue'


import ObjectivePlan from './notifivationCards/objective-plan.vue'
import _ from 'lodash';
import { adminComputed, adminMethods, handbookComputed, handbookMethods } from '@/state/helpers.js'
import { orderBy } from 'lodash';
    export default {
        data: () => ({
            show_archived_notifications: false,
        }),
        components:{
            //eslint-disable-next-line
            simplebar,
            //eslint-disable-next-line
            DocumentAcceptance,
            //eslint-disable-next-line
            ObjectivePlan,
            //eslint-disable-next-line
            ActionPlanCard,
        },
        computed: {
            ...adminComputed,
            ...handbookComputed,
            getSentItemCount(){
                let count = 0;
                this.all_notifications.forEach( item => {
                    //console.log(JSON.parse(JSON.stringify(item)));
                    if(item.status == 'Sent' && (item.system_type == 'document_acceptance' || item.system_type == 'objective_plan') && item.notifiable != null){
                        count++;
                    }
                })
                console.log(count)
                return count;
            },

            all_notifications(){
                //* return inbox items based on the 'show_archived_notifications' toggle state
                let uniq_notifications = this.user_inbox.filter( item => item.is_archived == this.show_archived_notifications );
                uniq_notifications = _.uniqBy(uniq_notifications, 'notifiable_id');
                return uniq_notifications;
            },
            document_notifications(){
                let document_notifications = this.all_notifications.filter( item => item.system_type == 'document_acceptance');
                document_notifications = orderBy(document_notifications, ['id'], ['desc'])
                return document_notifications
            },
            objective_plan_notifications(){
                let objective_plan_notifications = this.all_notifications.filter( item => item.system_type == 'objective_plan'  && item.notifiable != null);
                objective_plan_notifications = orderBy(objective_plan_notifications, ['id'], ['desc'])
                return objective_plan_notifications
            },
            new_notifications_filtered(){
                let data = this.new_notifications.filter( (item) => {
                    if(item.data.type == 'read_chat'){
                        return false;
                    }
                    if(item.data.type == 'unread_chat'){
                        return false;
                    }
                    return true;
                })
                return data
            },
            read_notifications_filtered(){
                let array = this.read_notifications.filter( (item) => {
                    if(item.data.type == 'read_chat'){
                        return false;
                    }
                    if(item.data.type == 'unread_chat'){
                        return false;
                    }
                    return true;
                })
                return array
            }
        },
        methods: {
            ...adminMethods,
            ...handbookMethods,
            getComponent(type){
                switch(type){
                    case 'unread_chat':{
                        return ChatCard
                    }
                    case 'key_action_assigned':{
                        return ActionPlanCard;
                    }
                    case 'document_assignment':{
                        return DocumentAcceptance;
                    }
                    default :{
                        console.log(type);
                    }
                }
            },
            handleInboxItemsStatusUpdate(payload) {
                console.log(payload);
                //let item_to_delete = this.all_notifications.filter( item => item.id == payload.inbox_id);
                let handleItem = this.all_notifications.find( item => {
                                console.log(JSON.parse(JSON.stringify(item)));
                                // console.log(id)
                                return item.id == payload.inbox_id
                            });
                console.log(handleItem);
            },
            handleInboxItemStatusUpdate(payload){
                if(payload.status == 'rejected'){
                    this.$swal.fire({
                        title: 'Item Rejection',
                        html: `<input type="text" id="login" class="swal2-input" placeholder="Reason for rejection">`,
                        confirmButtonText: 'Reject',
                        showCancelButton: true,
                        focusConfirm: false,
                        preConfirm: () => {
                            const reason = this.$swal.getPopup().querySelector('#login').value
                            if (!reason) {
                                this.$swal.showValidationMessage(`Please enter a reason`)
                            }
                            return { reason: reason }
                        }
                    }).then((result) => {
                        if(result.isDismissed || result.isDenied){
                            return;
                        }
                        this.handleNotificationChange(true);
                        this.setInboxItemStatus({...payload, reason: result.value.reason})
                        .then(()=>{
                            this.getUserInbox();
                            this.handleNotificationChange(false);
                            //* inform component that inbox status was updated (eg document acceptance list)
                            this.$eventHub.$emit('inboxUpdated');
                        })
                    })
                }
                else{
                    this.handleNotificationChange(true);
                    this.setInboxItemStatus(payload)
                    .then(()=>{
                        this.getUserInbox();
                        this.handleNotificationChange(false);
                        //* inform component that inbox status was updated (eg document acceptance list)
                        this.$eventHub.$emit('inboxUpdated');
                    })
                }
            }
        },
        mounted() {
            this.$eventHub.$on('updatedStatus', this.handleInboxItemStatusUpdate);
        },
        beforeDestroy(){
            this.$eventHub.$off('updatedStatus');
        }
    };
</script>

<style></style>
