<script>
import axios from "axios";

import store from '@/state/store'

import { settingsMethods, adminComputed, authMethods } from "@/state/helpers";

import i18n from "../i18n";

import PinnedMilestone from '@/components/menu/pinned-milestone.vue' 

import NotificationsList from '@/components/menu/notificationsDropdown.vue'

import DatepickerFilter from '@/components/menu/datePickerFilter.vue'

import licenseExpireTimer from "./menu/licenseExpireTimer.vue";

import tableHelpers from '@/mixins/tableHelpers.js';

//import simplebar from "simplebar-vue";

/**
 * Nav-bar Component
 */
export default {
    mixins: [tableHelpers],
    data() {
        return {

        languages: [
            {
            flag: require("@/assets/images/flags/us.jpg"),
            language: "en",
            title: "English",
            },
            {
            flag: require("@/assets/images/flags/french.jpg"),
            language: "fr",
            title: "French",
            },
            {
            flag: require("@/assets/images/flags/spain.jpg"),
            language: "es",
            title: "Spanish",
            },
            {
            flag: require("@/assets/images/flags/chaina.png"),
            language: "zh",
            title: "Chinese",
            },
            {
            flag: require("@/assets/images/flags/arabic.png"),
            language: "ar",
            title: "Arabic",
            },
        ],
        routeName: '',
        routes:[
            { id: 'model', value: 'Model'},
            { id: 'people', value: 'People'},
            { id: 'performance', value: 'Performance'},
            { id: 'handbook', value: 'Handbook'},
            { id: 'meeting', value: 'Meeting'},
            { id: 'scheduler', value: 'Scheduler'},
        ],
        lan: i18n.locale,
        text: null,
        flag: null,
        value: null,
        };

    },
    computed: {
        ...adminComputed,
        returnName() {
            let name = "";
            if (this.core != null) {
                name = this.core.full_name;
            }
            return name;
        },
        getProfileImage(){
            return this.core.avatar_src;
        },
        getFallbackImage(){
            return `../assets/images/logo-light-mini.png`;
        },
        isLocalhost(){
            //returns true for localhost env
            if(window.location.origin.includes('localhost')){
                return `
                    background: rgb(255,0,112) !important;
                    background: linear-gradient(90deg, rgba(255,0,112,1) 0%, rgba(81,29,253,1) 50%, rgba(74,255,0,1) 100%);
                `
            }
            else if(this.isDevEnv()){
                return `
                    background: rgb(238,174,202);
                    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
                `
            }
            return '';
        },
        visibilityCheck(){
            return this.canShowItemForRoute('performance') || this.canShowItemForRoute('structure');
        }

    },
    components: {
        PinnedMilestone,
        NotificationsList,
        licenseExpireTimer,
        DatepickerFilter
        /* simplebar */
    },
    mounted() {
        this.routeName = this.$route.name
        this.routes.forEach(route => {
            if(this.$route.name == 'handbook-with-param') {
                this.routeName = "handbook"
            } else if (route.id == this.$route.name) {
                this.routeName = route.value
            }
        })
        this.value = this.languages.find((x) => x.language === i18n.locale);
        this.text = this.value.title;
        this.flag = this.value.flag;
    },
    methods: {
        ...authMethods,
        ...settingsMethods,
        handleLogout(){
            const has_unsaved_changes = this.$allTablesUnsavedChangesCheck();
            if(has_unsaved_changes){
                //* stop here if unsaved changes are found
                return;
            }
            this.validate()
            .then((valid)=>{
                if(valid){
                    this.executeLogoutChain();
                }
                else{
                    this.resetVuex();
                    this.$router.push({name: 'login'});
                }
            })
            .catch(()=>{
                this.resetVuex();
                this.$router.push({name: 'login'});
            })
        },
        executeLogoutChain(){
            window.axios.get('api/auth/logout')
            .then(()=>{
                window.localStorage.removeItem('x_token');
                window.localStorage.removeItem('auth.currentUser');
                this.logOut().then(()=>{
                    this.resetVuex();
                    this.$router.push({name: 'login'});
                })
            })
            .catch(()=>{
                this.resetVuex();
            })
        },
        resetVuex(){
            store.dispatch('admin/clearStore');
            store.dispatch('levels/clearAll');
            store.dispatch('menu/clearAll');
            store.dispatch('people/clearAll');
            store.dispatch('activity/clearAll');
            store.dispatch('performance/objectives/clearAll')
            window.localStorage.removeItem('level_id');
        },
        toggleMenu() {
            this.$parent.toggleMenu();
            this.setLeftMenuState();
            this.$nextTick(()=>{
                //* for webix tables
                this.$eventHub.$emit('windowResizedEvent')
            })
            //this.$eventHub.$emit('leftMenuToggled')
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
                Element.ALLOW_KEYBOARD_INPUT
            );
            }
        } else {
            if (document.cancelFullScreen) {
            document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
            }
        }
        },
        setLanguage(locale, country, flag) {
        this.lan = locale;
        this.text = country;
        this.flag = flag;
        i18n.locale = locale;
        },
        logoutUser() {
        // eslint-disable-next-line no-unused-vars
        axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
            this.$router.push({
            name: "default",
            });
        });
        },
        changeStyle(type) {
        let r = document.querySelector(':root');
        let app = document.getElementById('mainContent')

        app.classList = '';
        if(type == 'small'){r.styl
            app.classList = 'small-size'
            //r.style.setProperty('--bs-body-font-size', '0.6rem');
        }
        if(type == 'medium'){
            //r.style.setProperty('--bs-body-font-size', '0.75rem');
        }
        if(type == 'large'){
            app.classList = 'large-size'
            //r.style.setProperty('--bs-body-font-size', '0.8rem');
        }
        },
        canShowItemForRoute(route){
            return this.$route.name === route ? true : false;
        }
  },
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header" ><!-- :style="isLocalhost" -->
            <div class="d-flex">
        <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link tag="a" to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo.svg" alt height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-dark.png" alt height="17" />
                        </span>
                    </router-link>

                    <router-link tag="a" to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img style="margin-left: -0.55rem;" src="@/assets/images/logo-light-mini.png" alt height="40" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-light.png" alt height="45" />
                        </span>
                    </router-link>
                </div>

                <button
                id="vertical-menu-btn"
                type="button"
                class="btn btn-sm px-3 font-size-16 header-item"
                style="max-height: 45px; margin-top: 0.9rem; z-index: 100"
                @click="toggleMenu"
                >
                    <font-awesome-icon class="fa-fw" icon="fa-solid  fa-bars" />
                </button>

                <DatepickerFilter v-show="$route.name != 'handbook'"/>

                <PinnedMilestone v-if="visibilityCheck" class="header-item" style="max-height: 45px; margin-top: 0.9rem;z-index: 100"/>
                
            </div>
            <h4 class="mt-2 route-header" v-bind:class="isDevEnv() ? 'red': ''" style="text-align: center; color:white; position: absolute; margin-left: auto;margin-right: auto;left: 185px; right: 0">{{ routeName }}</h4>
            
            <div class="d-flex">
                <licenseExpireTimer />
                
                <NotificationsList v-show="false" />
                <!-- class="custom-item-header" -->
                <b-dropdown
                
                v-if="core != null"
                right
                variant="black"
                toggle-class="header-item"
                menu-class="dropdown-menu-end"
                no-caret
                >
                <template v-slot:button-content>
                    <!-- img from b64 -->
                    <img
                    class="rounded-circle header-profile-user"
                    :src="getProfileImage"
                    alt=""
                    />
                    <span class="d-none d-xl-inline-block ms-1">{{ returnName }}</span>
                    <font-awesome-icon class="fa-fw" icon="fa-caret-down"/>
                </template>
                <!-- item-->
                <b-dropdown-item>
                    <router-link tag="span" :to="`/people/${core.id}`">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-user"/>
                    {{ $t("navbar.dropdown.henry.list.profile") }}
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <a @click="handleLogout" class="dropdown-item text-danger cursor-pointer">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1 text-danger" icon="fa-power-off"/>
                    {{ $t("navbar.dropdown.henry.list.logout") }}
                </a>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item v-if="isDevEnv()"  @click="$router.push({name: 'testzone'});">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-flask" />
                    TestZone
                </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>
<style>

.logoName {
  margin-left: -4.5rem;
  color: white;
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 0px;
}
.header-item {
    height: unset !important;
}
#vertical-menu-button {
    height: unset !important;
}
.logo {
    height: 45px !important;
    margin-bottom: 1.7rem
}
.red {
    color: red !important
}
@media (max-width: 650px) {
    .route-header {
        display:none
    }
}
</style>
